<template>
     <a-modal
        title="编辑"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="$emit('close')"
        @ok="handleSubmit"
        destroyOnClose
        :width="642"
    >
      <a-form
        :form="form"
        v-bind="formItemLayout"
    >
        <a-form-item label="分类">
             <a-select v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请选择分类' }] },]" >
                    <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
        </a-form-item>
        <a-form-item label="题库">
           <a-select v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请选择题库' }] },]" >
                <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                    {{item.name}}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="课程">
           <a-select v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请选择课程' }] },]" >
                <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                    {{item.name}}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="讲义">
           <a-select v-decorator="[ 'username',{ initialValue: initname.username, rules: [{ required: true, message: '请选择讲义' }] },]" >
                <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                    {{item.name}}
                </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="学习次数" >
            <a-input v-decorator="[ 'password', {rules: [{required: true, message: '请输入学习次数'}]}]" />
        </a-form-item>
        <a-form-item label="学习基数" v-if="!data">
            <a-input v-decorator="[ 'surepassword', {rules: [{required: true, message: '请输入学习基数'}]}]" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
export default {
    name: 'editSubject',
    props: ['visible', 'subjectList', 'data'],
     beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    data(){
        return{
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 20 },
            },
            confirmLoading: false
        }
    },
    methods:{
        handleSubmit(){
            this.form.validateFields((err, values) => {
                if(err) return;
            })
        }
    }
}
</script>